import axios from "axios";
import "firebase/compat/storage";
import { firebase } from "../services/config";
import "firebase/compat/database";
import request from "superagent";
import { saveTransaction } from "./transactionsComponent";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { baseApiUrl } from "../services/setup";

const db = firebase.database();

const apiUrl = baseApiUrl;

// const process = () => {
//   withReactContent(Swal).fire({
//     title: "Enter your Pin",
//     html:
//       "You are about to send " +
//       unit +
//       " " +
//       planType +
//       " " +
//       "Data" +
//       " to " +
//       phoneNumber,
//     input: "password",
//     allowOutsideClick: false,
//     allowEscapeKey: false,
//     preConfirm: () => {
//       if (Swal.getInput().value === varificationCode) {
//         Swal.fire({
//           title: "Processing...",
//           html: "Please wait while we process your transaction",
//           allowOutsideClick: false,
//           allowEscapeKey: false,
//           timerProgressBar: false,
//           didOpen: async () => {
//             Swal.showLoading();

//             const data = {
//               applicationId: applicantId,
//             };

//             await request
//               .post("https://api.mcchstfuntua.edu.ng/emailValidation.php")
//               .type("application/json")
//               .send(data)
//               .then((response) => {
//                 if (response.text === "ok") {
//                   Swal.fire({
//                     title: "Varified",
//                     text: "We have successfully varify your email. you can now proceed",
//                     icon: "success",
//                   }).then(() => {
//                     navigate("/payment", {
//                       state: { userEmail: email },
//                     });
//                   });
//                 }
//               })
//               .catch((err) => {
//                 console.log("Error message:", err.response);

//                 if (err.response && err.response.status === 400) {
//                   // Handle the error response with status code 400
//                   console.log("ERROR HERE", err.response.text);
//                 } else {
//                   // Handle other types of errors (e.g., network errors)
//                   console.error("Network error:", err);
//                 }
//               });
//           },
//         });
//       } else {
//         setErrorMsg("the code provided is invalid");
//       }
//     },
//   });
// };

export default async function buyData(reqData) {
  let status = "ok";

  const req = {
    userId: reqData.userId,
    url: "https://directortechs.com/api/data/",
    regBody: {
      network: reqData.networkId,
      mobile_number: reqData.phone,
      plan: reqData.plan,
      Ported_number: false,
    },
    amount: reqData.amount,
    service: "data",
    planName: reqData.planName,
    agent: reqData.userAgent,
  };

  await request
    .post(baseApiUrl + "data.php")
    .type("application/json")
    .send(req)
    .then((response) => {
      if (response.data) {
        console.log("Success:", response.data.message);
        status = response.data;
      } else {
        console.log("Response data is undefined or null");
        console.log("RESPONCE", response.body.message);

        status = response.body;
      }
    })
    .catch((err) => {
      // console.log("Error message:", err.response); // or err.response.body

      status = err.response;

      if (err.response && err.response.status === 400) {
        // Handle the error response with status code 400
        const errorResponseData = err.response.body;

        status = errorResponseData.message;
      } else {
        // Handle other types of errors (e.g., network errors)
        // console.error("Network error:", err);
        status = err.text;
      }
    });

  return status;
}

export async function buyAirtime(reqData) {
  const req = {
    userId: reqData.userId,
    url: "https://directortechs.com/api/topup/",
    regBody: {
      network: reqData.networkId,
      amount: reqData.amount,
      mobile_number: reqData.phone,
      Ported_number: false,
      airtime_type: reqData.plan,
    },
    amount: reqData.amount,
    service: "airtime",
    agent: reqData.userAgent,
  };
}

export async function buyDataCard(reqData) {
  let status = "ok";

  const req = {
    userId: reqData.userId,
    url: "https://legitdataway.com/api/data_card",
    regBody: {
      network: reqData.networkId,
      plan_type: reqData.plan,
      quantity: reqData.quantity,
      card_name: reqData.cardName,
    },
    amount: reqData.amount,
    service: "datacard",
    agent: reqData.userAgent,
  };

  await request
    .post("https://perfect-gray-fish.cyclic.app/foudhanapi")
    .type("application/json")
    .send(req)
    .then((response) => {
      console.log("Success:", response);

      status = response;
    })
    .catch((err) => {
      // console.log("Error message:", err.response); // or err.response.body
      // console.log("ERROR", err);
      status = err.response.text;
    });

  return status;
}

export async function bulkSms(reqData) {
  let status = "ok";

  const userid = reqData.userId;
  const userAgent = reqData.userAgent;
  const message = reqData.message;
  const recipient = reqData.recipient;
  const sender = reqData.sender;
  const numCount = reqData.numCount;

  await request
    .post(apiUrl + "/bulksms.php")
    .type("application/x-www-form-urlencoded")
    .send(
      `user_id=${userid}&sender=${sender}&message=${message}&numbers=${recipient}&numCount=${numCount}&agent_id=${userAgent}`
    )
    .then((response) => {
      const resObj = JSON.parse(response.text);

      const date = new Date();
      const formattedDate = date.toLocaleDateString("en-GB");

      const transaction = {
        service: "bulksms",
        details: "You have succesfully send BULK messages",
        refNo: resObj.request_id,
        date: formattedDate,
      };

      saveTransaction(transaction, userid);
    })
    .catch((err) => {
      // console.log("Error message:", err); // or err.response.body

      status = err.response.text + "somthing went wrong";
    });

  return status;
}

export async function electricity(reqData) {
  let status = "ok";

  const req = {
    userId: reqData.userId,
    url: "https://legitdataway.com/api/bill",
    regBody: {
      disco: reqData.disco,
      meter_number: reqData.meterNumber,
      meter_type: reqData.meterType,
      amount: reqData.amount,
      "request-id": reqData.orderId,
      bypass: false,
    },
    amount: reqData.amount,
    service: "electricity",
    agent: reqData.userAgent,
  };

  await request
    .post(baseApiUrl + "electricity.php")
    .type("application/json")
    .send(req)
    .then((response) => {
      if (response.data) {
        console.log("Success:", response.data.message);
        status = response.data;
      } else {
        console.log("Response data is undefined or null");
        console.log("RESPONCE", response.body.message);

        status = response.body;
      }
    })
    .catch((err) => {
      console.log("Error message:", err.response); // or err.response.body

      status = err.response;

      if (err.response && err.response.status === 400) {
        // Handle the error response with status code 400
        const errorResponseData = err.response.body;

        console.log("ERROR", errorResponseData);
        status = errorResponseData.message;
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Network error:", err);
        status = err.text;
      }
    });

  return status;
}

export async function utility(reqData) {
  const userid = reqData.userId;
  const userAgent = reqData.userAgent;
  let status = "ok";

  const disco = reqData.disco;
  const meterNumber = reqData.meterNumber;
  const meterType = reqData.meterType;
  const amount = reqData.amount;
  const orderId = reqData.orderId;

  console.log("REG:", reqData);

  const req = {
    userId: userid,
    url: "https://legitdataway.com/api/bill",
    regBody: {
      disco: disco,
      meter_number: meterNumber,
      meter_type: meterType,
      amount: amount,
      "request-id": orderId,
      bypass: false,
    },
    amount: amount,
    service: "electricity",
    agent: userAgent,
  };

  console.log(req);

  await request
    .post("https://perfect-gray-fish.cyclic.app/foudhanapi")
    .type("application/json")
    .send(req)
    .then((response) => {
      console.log("Success:", response.body);
      console.log(response);
    })
    .catch((err) => {
      console.log("Error message:", err.response); // or err.response.body
      console.log("ERROR", err);
      status = err.response.text;
    });

  return status;
}

export async function posOrder(reqData) {
  let status = "ok";

  db.ref("/Admin/orders")
    .push(reqData)
    .then((e) => {
      console.log("Success:", e);
      console.log(e);
    })
    .catch((err) => {
      console.log("Error message:", err.response);
      console.log("ERROR", err);
      status = err.response.text;
    });

  return status;
}
